<template>
  <div class="wrapper">
    <div class="header">
      <span>
        <admin-title :title="$route.meta.name"></admin-title>
      </span>
      <span>
        <el-button type="info" size="small" @click="$router.back()">返回</el-button>
      </span>
    </div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="昵称">{{ detail.nickname }}</el-descriptions-item>
      <el-descriptions-item label="姓名">{{ detail.realname }}</el-descriptions-item>
      <el-descriptions-item label="头像">
        <el-image style="width: 60px; height: 60px" :src="detail.face" :preview-src-list="[detail.face]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="手机号" :span="1">{{ detail.mobile }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { detailAPI } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      detail: {}
    }
  },

  mounted() {
    this.getDetail()
  },

  methods: {
    async getDetail() {
      this.detail = await detailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
