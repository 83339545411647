import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/basic/user/list',
    method: 'get',
    params
  })
}

export function editAPI(data) {
  return http({
    url: '/manage/user/edit',
    method: 'post',
    data
  })
}

export function delAPI(id) {
  return http({
    url: '/manage/user/del',
    method: 'post',
    data: { id }
  })
}

export function detailAPI(id) {
  return http({
    url: '/company/basic/user/detail',
    method: 'get',
    params: { id }
  })
}
